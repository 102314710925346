import React from 'react'
import './Styles/FLL.scss'

import ImageSlider from '../Components/ImageSlider/ImageSlider.js'
import {HashLink} from 'react-router-hash-link'
import ContentPrompt from "../Components/ContentPrompt/index.js"
import ProductCard from "../Components/ProductCard/index.js"
import { AiOutlinePlus } from 'react-icons/ai'
import Marquee from 'react-fast-marquee'

import Competitions1 from "../Assets/FLL/Competitions1.JPG"
import Competitions2 from "../Assets/FLL/Competitions2.JPG"
import Competitions3 from "../Assets/FLL/Competitions3.JPG"
import Competitions4 from "../Assets/FLL/Competitions4.JPG"
import Competitions5 from "../Assets/FLL/Competitions5.JPG"
import Competitions6 from "../Assets/FLL/Competitions6.JPG"
import Competitions7 from "../Assets/FLL/Competitions7.JPG"
import Competitions8 from "../Assets/FLL/Competitions8.JPG"
import Competitions9 from "../Assets/FLL/Competitions9.JPG"
import Competitions10 from "../Assets/FLL/Competitions10.JPG"
import Competitions11 from "../Assets/FLL/Competitions11.JPG"
import Competitions12 from "../Assets/FLL/Competitions12.JPG"
import Competitions13 from "../Assets/FLL/Competitions13.JPG"
import Competitions14 from "../Assets/FLL/Competitions14.jpg"
import Competitions16 from "../Assets/FLL/Competitions16.jpg"
import Competitions17 from "../Assets/FLL/Competitions17.jpg"
import Competitions18 from "../Assets/FLL/Competitions18.jpg"
import Competitions20 from "../Assets/FLL/Competitions20.jpg"
import Competitions21 from "../Assets/FLL/Competitions21.jpg"
import Competitions22 from "../Assets/FLL/Competitions22.jpg"
import Competitions23 from "../Assets/FLL/Competitions23.jpg"
import Competitions24 from "../Assets/FLL/Competitions24.jpg"
import Competitions25 from "../Assets/FLL/Competitions25.jpg"
import Competitions26 from "../Assets/FLL/Competitions26.jpg"
import Competitions28 from "../Assets/FLL/Competitions28.jpg"
import Competitions29 from "../Assets/FLL/Competitions29.jpg"
import Competitions30 from "../Assets/FLL/Competitions30.jpg"
import Competitions31 from "../Assets/FLL/Competitions31.jpg"
import Competitions32 from "../Assets/FLL/Competitions32.jpg"
import Competitions33 from "../Assets/FLL/Competitions33.png"
import Competitions34 from "../Assets/FLL/Competitions34.png"
import Competitions35 from "../Assets/FLL/Competitions35.jpg"
import Competitions36 from "../Assets/FLL/Competitions36.jpg"
import Competitions38 from "../Assets/FLL/Competitions38.jpg"
import Competitions39 from "../Assets/FLL/Competitions39.jpg"
import Competitions40 from "../Assets/FLL/Competitions40.jpg"
import Demos1 from "../Assets/FLL/Demos1.JPG"
import Demos2 from "../Assets/FLL/Demos2.JPG"
import Demos3 from "../Assets/FLL/Demos3.jpg"
import Demos4 from "../Assets/FLL/Demos4.jpg"
import Demos5 from "../Assets/FLL/Demos5.jpg"
import Demos6 from "../Assets/FLL/Demos6.jpg"
import Modulink2 from "../Assets/FLL/Modulink2.jpg"




import AOS from 'aos'

const introImages=[
  {image:   Competitions6}, {image: Competitions8}, {image: Competitions24}
]

const history1Images = [
  {image:    Competitions12}, {image:    Competitions21}, {image:    Competitions40}, {image: Competitions18}
]

const outreach1Images =  [
  {image:    Demos6}, {image:    Demos4}, {image:    Demos5}
]

const outreach2Images =  [
  {image:    Demos2}, {image:    Demos1}, {image:    Demos3}
]

const modulink1Imgs =  [
  {image:    Modulink2}, {image:    Competitions30}, {image:    Competitions33}
]

const modulink2Imgs =  [
  {image:    Competitions34}, {image:    Competitions14}, {image:    Competitions3}
]


AOS.init()

class FLLPage extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {promptEnabled: false, promptName: null, faqEnabled: false, currentImageIndex: 0}
    this.OpenExampleGame = this.OpenExampleGame.bind(this)
    this.handleScroll = this.handleScroll.bind(this)
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)  }

  handleScroll() {
    const teamHistoryElement = document.getElementById('teamhistory');
    if (teamHistoryElement) {
      const rect = teamHistoryElement.getBoundingClientRect();
      const scrollHeight = window.scrollY + rect.top;
      const index = Math.min(
        Math.floor((window.scrollY - scrollHeight) / 200),
        history1Images.length - 1
      );
      this.setState({ currentImage: history1Images[index].image, currentImageIndex: index });
    }
  }

  OpenExampleGame() {
    const promptEnabled = this.state.promptEnabled
    if (!promptEnabled) {
      this.setState({promptEnabled: true, promptName: "ModuLinkGame"})
    }
  }

  contentCallback = () => { console.log("callback called"); this.setState({promptEnabled: false}) }
  
  render() {

    let promptObject = (null);
    if (this.state.promptEnabled) {
      promptObject = <ContentPrompt name={this.state.promptName} callback={this.contentCallback}/>
    }

    AOS.init();

    return (
      <>
        <div className='FLL-header'>
          <h1>FLL</h1>
          <h2>Our competitive middle school teams (3204 and 20157)!</h2>
        </div>
        <div className='FLL-content'>
          <div className='about' data-aos="fade-up">
            <h1 data-aos="fade-right">What is FLL?</h1>
            <hr />
            <h2>FLL is a competition directed at students ages 4-14. Our 2 teams (3204 and 20157) are comprised of middle schoolers and compete in the Challenge division of FLL. In this competition, we compete in competitions across the nation, building robots, designing research projects, and spreading STEM across our community.</h2>
            <div data-aos="fade-left">
              <ImageSlider slides={introImages} />
            </div> 
            <HashLink smooth to="#outreach" data-aos="fade-up">Check out our FLL outreach!</HashLink>
          </div>
          <div className='outreach' id="outreach" data-aos="fade-up">
            <h1>Outreach</h1>
            <hr />
            <div className='row' data-aos="fade-right">
              <div>
                <h1>Elementary Mentoring</h1>
                <h2>Recently, during the 2023-2024 school year, our FLL teams and club as a whole helped a nearby elementary school, Niemes Elementary, startup their FLL team after a few years of taking a step back from the competition. This trial run helped them get back into the competition and also help our students gain valuable teaching and mentoring experience. We are also reaching out to other schools, such as Wittman Elementary, to help them with their teams.</h2>
              </div>
              <ImageSlider slides={outreach1Images} />
            </div>
            <div className='rtext' data-aos="fade-left">
              <ImageSlider slides={outreach2Images} />
              <div>
                <h1>Demos</h1>
                <h2>Our teams participate in many demos at school events across our district and sometimes with organizations. These help our students gain PVSA volunteering hours and helps spread STEM across our district, showing other what we do and cultivating an interest for STEM in the process.</h2>              
              </div>
            </div>
          </div>
          <div className='modulink' data-aos="fade-up">
            <h1>ModuLink</h1>
            <hr />
            <div className='row'>
              <div>
                <h1>Program</h1>
                <h2>ModuLink is our club's sustainability program aimed at our school's team members which consists of an FTC-like game but fitted for FLL. Our club generates seasonal maps and challenges for our teams to get through in the off-season. Although it's only a year old, there are no plans of stopping, and our members benefit from having ways to stay involved during the off season.</h2>              
              </div>
              <ImageSlider slides={modulink1Imgs} />
            </div>
            <Marquee className='marquee'>
              <img src={ Competitions1} />
              <img src={ Competitions22} />
              <img src={ Competitions25} />
              <img src={ Competitions4} />
              <img src={ Competitions28} />
              <img src={ Competitions5} />
              <img src={ Competitions7} />
              <img src={ Competitions39} />
              <img src={ Competitions35} />
              <img src={ Competitions13} />
              <img src={ Competitions29} />
              <img src={ Competitions23} />
            </Marquee>
            <div className='row'>
              <div>
                <h1>Purpose</h1>
                <h2>ModuLink is meant to help keep our team members involved during the off season and also help them keep their skills sharp. It also serves another purpose, however. In having an FTC-like field, it helps our graduating FLL members prepare for FTC, getting comfortable with the different stages of the game and the different objectives that come with FTC.</h2>              
              </div>
              <ImageSlider slides={modulink2Imgs} />
            </div>
            <Marquee className='marquee' direction='right'>
              <img src={ Competitions17} />
              <img src={ Competitions9} />
              <img src={ Competitions10} />
              <img src={ Competitions16} />
              <img src={ Competitions11} />
              <img src={ Competitions20} />
              <img src={ Competitions26} />
              <img src={ Competitions32} />
              <img src={ Competitions31} />
              <img src={ Competitions36} />
              <img src={ Competitions38} />
              <img src={ Competitions2} />
            </Marquee>
          </div>
          <div className='teamhistory' id='teamhistory' data-aos="fade-up">
            <h1>Team History</h1>
            <hr />
            <div className='row'>
              <div className='container' data-aos="fade-right">
                <div className='col'>
                  {history1Images.map((image, idx) => (
                    <img
                      key={idx}
                      src={this.state.currentImage}
                      className={idx === this.state.currentImageIndex ? 'active' : ''}
                      alt={`Image ${idx}`}
                    />
                  ))}
                </div>
              </div>
              <div className='history'>
                <ul>
                  <h3><b>2006-2007 NAND Quest</b></h3>
                  <hr />
                    <li>Team 3204: Championship - Innovation Project Award</li>
                    <br />

                  <h3><b>2007-2008 Power Puzzle</b></h3>
                  <hr />
                    <li>Team 3204: World Championships - 2nd Innovation Project Award</li>
                    <li>Team 3204: Championship - Mentor Award</li>
                    <br />

                  <h3><b>2008-2009 Climate Connections</b></h3>
                  <hr />
                    <li>Team 3204: Qualifier - Robot Performance Award</li>
                    <br />

                  <h3><b>2009-2010 Smart Move</b></h3>
                  <hr />
                    <li>Team 3204: Championship - 3rd Robot Design Award</li>
                    <br />

                  <h3><b>2011-2012 Food Factor</b></h3>
                  <hr />
                    <li>Team 3204: Championship - Champions Award</li>
                    <br />

                  <h3><b>2012-2013 Senior Solutions</b></h3>
                  <hr />
                    <li>Team 3204: World Championships - Mentor Award</li>
                    <br />

                  <h3><b>2013-2014 Nature's Fury</b></h3>
                  <hr />
                    <li>Team 3204: Regional - Teamwork Award</li>
                    <br />

                  <h3><b>2016-2017 Animal Allies</b></h3>
                  <hr />
                    <li>Team 3204: Qualifier - 2nd Innovation Project Award</li>
                    <br />

                  <h3><b>2022-2023 Superpowered</b></h3>
                  <hr />
                    <li>Team 3204: Regional - Robot Design Award</li>
                    <li>Team 20157: Qualifier - Core Values Award</li>
                    <br />

                  <h3><b>2023-2024 Masterpiece</b></h3>
                  <hr />
                    <li>Team 3204: Regional - Innovation Project Award</li>
                    <li>Team 3204: Qualifier - Champions Award, 2nd Robot Performance Award</li>
                    <li>Team 20157: Qualifier - Robot Design Award</li>
                    <br />
                </ul>
              </div>
              <div className='container' data-aos="fade-left">
                <div className='col'>
                  {history1Images.map((image, idx) => (
                    <img
                      key={idx}
                      src={this.state.currentImage}
                      className={idx === this.state.currentImageIndex ? 'active' : ''}
                      alt={`Image ${idx}`}
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className='mentors' data-aos="fade-up">
            <h1>Mentors</h1>
            <hr />
            <div className='row'>
              <div className='lcol'>
                <p><b>Student Mentors</b></p>
                <p><b>Heads:</b> Navya Manni, Sahil Julka</p>
                <p><b>Mentors:</b> Brandon Chen, Nathaniel Soco, Omkar Siddeswaran</p>
              </div>
              <div className='rcol'>
                <p><b>Alumni and Staff Mentors</b></p>
                <p><b>Coaches:</b> Terry Domae, Jeramy Woosung Yoon</p>
                <br></br>
                <p><b>Mentors:</b> Matthew Dung, Steve Wong, Charles Weir, Li-Chen (Gemini) Chang</p>
                <br></br>
                <p><b>Alumni and Mentors:</b> Benjamin Domae, Tony Ngo, Raymond Bolanos, Lucy Wang, Kevin Chou, Sophia Lau, Moses Won, Richard Yeong, Lawrence Dang, Jason Jewik, Tobias Kim, Ian Hudson, Ivan Mao, Amar Ghandi, Nathan Delos Santos, Dina Li, Zunaira Baig, Kirsten Yen, Varn Bharadwaj, Bryant Huang, Saketh Somayajula, Perry Han, Aleena Kang, Sid Shah, Sofia Nowell, Nairrit Sengupta, Christine Liu, Jeffery Liao, Jorlin Chai, Arun Nambiar, Arhum Jain, Jaktaiga Thomas, Jayden Hong</p>
              </div>
            </div>
          </div>
        </div>
        {promptObject}
      </>
    )
  }
}

export default FLLPage