import Image1 from "../../Assets/RoboCamp/Image1.JPG"
import Image2 from "../../Assets/RoboCamp/Image2.JPG"
import Image3 from "../../Assets/RoboCamp/Image3.JPG"

export const SliderData = [
    {
        image: Image1
    },
    {
        image: Image2
    },
    {
        image: Image3
    }
]