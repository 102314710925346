import React from 'react'
import './InfoCard.scss'

import AOS from 'aos';
import 'aos/dist/aos.css';

const InfoCard = (props) => {
  
    const descLines = props.desc.split('\n').map((line, index) => (
      <React.Fragment key={index}>
        {line}
        <br />
      </React.Fragment>
    ));
    
    return (
      <div>
      <head>
    <link rel="preconnect" href="https://fonts.googleapis.com"/>
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin/>
    <link href="https://fonts.googleapis.com/css2?family=Lexend&family=Playfair+Display&family=Playfair+Display+SC:ital@1&display=swap" rel="stylesheet"></link>
      </head>
      <div className="info-card" data-aos = "fade-right"  style={{ background: props.bgColor }}>
        
        <h1>{props.name}</h1>
        <p>{descLines}</p>
      </div>
      </div>
      
      /*
    <button className='learn-more' onClick={props.clickHandler}>Learn More</button>
      <a href="/RoboCampSignup" className='enroll'>Signup Now</a>
      */
    );
  };



export default InfoCard