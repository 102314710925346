import React, { useEffect, useState } from 'react'
import "./Styles/Home.scss"
import TypeWriter from 'typewriter-effect'

import ImageSlider from '../Components/ImageSlider/ImageSlider'
import { SliderData } from '../Components/ImageSlider/SliderData'
import { HashLink } from 'react-router-hash-link'
import SponsorCard from '../Components/SponsorCard'
import PFP from '../Assets/FTC/Thumbnail.png'
import Adit from '../Assets/Home/Cabby/Adit.png'
import Alexander from '../Assets/Home/Cabby/Alexander.png'
import Ash from '../Assets/Home/Cabby/Ash.png'
import Akshita from '../Assets/Home/Cabby/Akshita.png'
import Brandon from '../Assets/Home/Cabby/Brandon.png'
import Bryan from '../Assets/Home/Cabby/Bryan.png'
import Joseph from '../Assets/Home/Cabby/Joseph.png'
import Luv from '../Assets/Home/Cabby/Luv.png'
import Millan from '../Assets/Home/Cabby/Millan.png'
import Mohana from '../Assets/Home/Cabby/Mohana.png'
import Mohnish from '../Assets/Home/Cabby/Mohnish.png'
import Nathaniel from '../Assets/Home/Cabby/Nathaniel.png'
import Navya from '../Assets/Home/Cabby/Navya.png'
import Niranjan from '../Assets/Home/Cabby/Niranjan.png'
import Omkar from '../Assets/Home/Cabby/Omkar.png'
import Sahil from '../Assets/Home/Cabby/Sahil.png'
import Siddhant from '../Assets/Home/Cabby/Siddhant.png'
import { NavigationType } from 'react-router-dom'


const Home = () => {

  const [cabinetJSON, setCabinetJSON] = useState(null)

  useEffect(() => {
    fetch('https://script.google.com/macros/s/AKfycbyXfNUcLe0vfZiL4ioFljro4UHxw8Ot4Z5I461RdupuFfRATXm-nNii3NYHLL5PfJA/exec')
    .then(response => response.json())
    .then(data => setCabinetJSON(data))
  }, [])

  return (
    <>
      <div className='home-one'>
        <h1>Whitney High School Robotics</h1>
        <TypeWriter 
          options={{
            strings: ["Welcome to WHS Robotics!", "We are the engineers of the future...", "Building a community that will last a lifetime..."],
            autoStart: true,
            loop: true
          }}
        />          
        <a href="https://docs.google.com/forms/d/e/1FAIpQLScDs1bHZPJiC-n-jgMxwI7G4mBH846H6uYGpcY8Dw2oo_N1yA/viewform">Sign up for our club!</a>
        <HashLink smooth to="#mission" data-aos="fade-up">Learn more about our mission</HashLink>
      </div>
      <div className='home-two' id="mission">
        <h1>Our Mission</h1>
        <hr />
        <div className='row computer'>
          <div className='column'>
            <p>WHS Robotics originated in 2002 as an official club of Whitney High School in Cerritos, California. Whitney High School is home to academically gifted students throughout the ABC Unified School District, serving students from grades 7 to 12 and has gained a reputation as the top school in California and one of the finest in the nation. Currently our club administers multiple competition teams including FIRST Tech Challenge (FTC) Team #542, and FIRST LEGO League (FLL) team 3204. We also perform outreach events at a variety of locations in our community, including local libraries and elementary schools, with the goal of promoting STEM and robotics.</p>
            <p><b>You can get more details on each specific part of our club by exploring our site through the navigation bar above!</b></p>
          </div>
          <ImageSlider slides={SliderData} fadeType="fade-left"/>
        </div>
        <div className='mobile'>
          <p>WHS Robotics originated in 2002 as an official club of Whitney High School in Cerritos, California. Whitney High School is home to academically gifted students throughout the ABC Unified School District, serving students from grades 7 to 12 and has gained a reputation as the top school in California and one of the finest in the nation. Currently our club administers multiple competition teams including FIRST Tech Challenge (FTC) Team #542, and FIRST LEGO League (FLL) team 3204. We also perform outreach events at a variety of locations in our community, including local libraries and elementary schools, with the goal of promoting STEM and robotics.</p>
          <p><b>You can get more details on each specific part of our club by exploring our site through the navigation bar above!</b></p>
          <ImageSlider slides={SliderData} fadeType="fade-up"/>
        </div>
      </div>
      <div className='home-two'>
        <h1>School and Club History</h1>
        <hr />
        <div className='row computer'>
          <div className='column'>
            <p>WHS Robotics is a club at Whitney High School, the #1 High School in California, and located in Cerritos. Our school serves grades 7th to 12th in the ABC Unified School District. Whitney High School is an academically driven school, earning the Blue Ribbon and California Distinguished School awards multiple times. For numerous years, our school has been named #3 in California and ranked in the Top 30 in the nation.</p>
            <p>Our club is student-run and has had teams competing in FIRST Robotics Challenge, FIRST Lego League, FIRST Tech Challenge, VEX IQ, and VEX Robotics Competition. Our club now consists of FTC and FLL teams. Many of our teams have competed and placed in international competitions. Our club originated in 2002 with the help of our lovely, retired club advisors, Ms. Sandra Bruesch and Ms. Marilou McSherry.</p>
          </div>
        </div>
        <div className='mobile'>
          <p>WHS Robotics is a club at Whitney High School, the #2 High School in California, and located in Cerritos. Our school serves grades 7th to 12th in the ABC Unified School District. Whitney High School is an academically driven school, earning the Blue Ribbon and California Distinguished School awards multiple times. For numerous years, our school has been named #1 in California and ranked in the Top 15 in the nation.</p>
          <p>Our club is student-run and has had teams competing in FIRST Robotics Challenge, FIRST Lego League, FIRST Tech Challenge, VEX IQ, and VEX Robotics Competition. Our club now consists of FTC and FLL teams. Many of our teams have competed and placed in international competitions. Our club originated in 2002 with the help of our lovely, retired club advisors, Ms. Sandra Bruesch and Ms. Marilou McSherry.</p>
        </div>
        <HashLink smooth to="#team" data-aos="fade-up">Meet the team and our advisors!</HashLink>
      </div>
      <div className='home-three' id="team">
        <h1>Meet the team</h1>
        <hr />
        <div className='flex-grid web' data-aos="fade-up">
            <h2>Club Leadership Team</h2>
            <div className='col'>
            <SponsorCard logo={Siddhant} title="Siddhant Khapra" desc="Meet Siddhant Khapra, our Co-President! He loves tackling new challenges and has picked up a bunch of cool skills. His favorite button on the calculator is the simplification button, and he’s eyeing the LEGO Concorde. When he’s not into robotics, Siddhant enjoys learning, playing tennis, trying new foods, and hanging out with friends." role="Co-President"/>
              <SponsorCard logo={Akshita} title="Akshita Shivakumar" desc="Say hi to Akshita Shivakumar, our President and FTC Captain! She’s all about the amazing resources we have, from tech to mentors. Her favorite feature is the off button, and she’s keen on the Ship in a Bottle 92177. In her free time, Akshita loves CAD design, reading, rock climbing, and Minecraft." role="Co-President"/>
              <SponsorCard logo={Mohnish} title="Mohnish Sivakumar" desc="Meet Mohnish Sivakumar, our Co-Secretary! He loves collaborating with his peers and innovating. His favorite button on a calculator is the natural log button, and his favorite LEGO set is Nano Gauntlet. In his free time, he watches K-dramas and K-pop and sings." role="Secretary"/>
            </div>
            <div className='col'>
            <SponsorCard logo={Adit} title="Adit Shah" desc="Meet Adit Shah, our other Co-Secretary! His favorite thing about robotics club is the programs that allow students to appreciate STEM. His favorite button on the calculator is pi, and his favorite LEGO set is the McLaren P1. In his free time, he listens to music, plays volleyball, and hangs out with friends." role="Secretary"/>
            <SponsorCard logo={Mohana} title="Mohana Sai" desc="Meet Mohana Sai, our Treasurer for Internal Affairs! She’s excited by robotics for its creative potential and teaching kids. Her favorite button is EE, and she’s interested in the LEGO Technic McLaren or the Mercedes F1. In her free time, she plays with her dog, watches TV, enjoys boba, solves puzzles, or visits Disneyland." role="Treasurer of Internal Affairs"/>
            <SponsorCard logo={Niranjan} title="Niranjan Janardhanan" desc="Meet Niranjan Janardhanan, our Treasurer for External Affairs! He’s passionate about robotics and loves the = button on his calculator for finding answers. He’s eyeing the LEGO Technic McLaren and enjoys catching up on sleep or watching YouTube." role="Treasurer of External Affairs"/>
              <SponsorCard logo={Navya} title="Navya Manni" desc="Navya Manni is our Lead Mentor who loves the opportunities and experiences the WHS Robotics club offers. Her favorite LEGO set is the NASA Artemis Space Launch System. Her favorite button on the calculator is the clear all button, and she enjoys reading, hanging out with friends, or catching up on sleep in her free time." role="Head FLL Mentor"/>
            </div>
            <div className='col'>
            <SponsorCard logo={Sahil} title="Sahil Julka" desc="Sahil Julka is our Head FLL Mentor with a passion for introducing people to the world of engineering. His favorite LEGO set is the Saturn V, and his favorite button on the calculator is the exponent button. When he’s not mentoring, he spends his time studying, playing sports, or playing games." role="Head FLL Mentor"/>
              <SponsorCard logo={Luv} title="Luv Kumar" desc="Meet Luv Kumar, our Director of Electrical Engineering! He's all about building and the joy of something functioning after working on it for a long time. When he's not working or building, he likes to read manga, watch anime, and scroll through social media." role="Director of Engineering"/>
              <SponsorCard logo={Alexander} title="Alexander Cheng" desc="Meet Alex Cheng, our Director of Educational Outreach! He loves building and also the club's new expedition, Intellikits! When he's not busy, he plays basketball of dives into rounds of Fortnite." role="Director of Educational Outreach"/>
              <SponsorCard logo={Ash} title="Ash Profeta" desc="Meet Anne-Beatrix Profeta, our Director of Activities! She’s all about outreach and meeting new people—it’s what makes her tick. When she’s not organizing awesome events, you’ll find her chilling with a book, napping, or enjoying a walk with some good tunes." role="Director of Activities"/>
            </div>
            <div className='col'>
            <SponsorCard logo={Millan} title="Millan Hamilton" desc="Meet Millan Hamilton, our Director of Technology! He’s all about learning, from STEM and programming to leadership. When he’s not diving into AI or building cool stuff with LEGO, he’s binge-watching Breaking Bad or playing chess." role="Director of Technology"/>
              <SponsorCard logo={Joseph} title="Jeongseop Yoon" desc="Jeongseop Yoon is one of our Co-Directors of Publicity who loves interacting with robotics parts. Despite being a builder, he enjoys programming because it is enjoyable to observe the robot function based on his input. His favorite LEGO set is the NASA Apollo Saturn V Space Rocket and Vehicles. His favorite button on the calculator is the back/delete button, and he plays the guitar in his free time." role="Director of Publicity"/>
              <SponsorCard logo={Bryan} title="Bryan Hsu" desc="Bryan Hsu is the other Co-Director of Publicity who loves the opportunities and the community in robotics. Like Jeongseop, he is also a builder, but likes programming. His favorite LEGO set is the McLaren Formula 1 Race Car. His favorite button on the calculator is the equal button, and he enjoys tinkering in his free time." role="Director of Publicity"/>            
            </div>
            <div className='col'>
            <SponsorCard logo={Nathaniel} title="Nathaniel Soco" desc="Nathaniel Soco is a builder that loves the home meetings at robotics. His favorite lego set is the McLaren P1™. His favorite button on the calculator is the Off button, and he sleeps and plays video games in his free time." role="FLL Mentor"/>
              <SponsorCard logo={Omkar} title="Omkar Siddeswaran" desc="Omkar Siddeswaran is a programmer that loves publicity and the sense of family in robotics. His favorite lego set is the Koenigsegg Jesko Absolut White Hypercar. His favorite button on the calculator is the Rad button. He spends his free time watching TV, reading, or playing with his dog." role="FLL Mentor"/>
              <SponsorCard logo={Brandon} title="Brandon Chen" desc="Brandon Chen is a builder that loves talking and working with people. His favorite lego set is the NASA Space Shuttle Discovery. His favorite button on the calculator is the On button, and he reads in his free time." role="FLL Mentor"/>            
            </div>
          </div>
          <div className='flex-grid mobile1' data-aos="fade-up">
            <h2>Club Leadership Team</h2>
            <div className='col'>
            <SponsorCard logo={Siddhant} title="Siddhant Khapra" desc="Meet Siddhant Khapra, our Co-President! He loves tackling new challenges and has picked up a bunch of cool skills. His favorite button on the calculator is the simplification button, and he’s eyeing the LEGO Concorde. When he’s not into robotics, Siddhant enjoys learning, playing tennis, trying new foods, and hanging out with friends." role="Co-President"/>
            <SponsorCard logo={Akshita} title="Akshita Shivakumar" desc="Say hi to Akshita Shivakumar, our President and FTC Captain! She’s all about the amazing resources we have, from tech to mentors. Her favorite feature is the off button, and she’s keen on the Ship in a Bottle 92177. In her free time, Akshita loves CAD design, reading, rock climbing, and Minecraft." role="Co-President"/>
            <SponsorCard logo={Mohnish} title="Mohnish Sivakumar" desc="Meet Mohnish Sivakumar, our Co-Secretary! He loves collaborating with his peers and innovating. His favorite button on a calculator is the natural log button, and his favorite LEGO set is Nano Gauntlet. In his free time, he watches K-dramas and K-pop and sings." role="Secretary"/>
            </div>
            <div className='col'>
            <SponsorCard logo={Adit} title="Adit Shah" desc="Meet Adit Shah, our other Co-Secretary! His favorite thing about robotics club is the programs that allow students to appreciate STEM. His favorite button on the calculator is pi, and his favorite LEGO set is the McLaren P1. In his free time, he listens to music, plays volleyball, and hangs out with friends." role="Secretary"/>
              <SponsorCard logo={Mohana} title="Mohana Sai" desc="Meet Mohana Sai, our Treasurer for Internal Affairs! She’s excited by robotics for its creative potential and teaching kids. Her favorite button is EE, and she’s interested in the LEGO Technic McLaren or the Mercedes F1. In her free time, she plays with her dog, watches TV, enjoys boba, solves puzzles, or visits Disneyland." role="Treasurer of Internal Affairs"/>
              <SponsorCard logo={Niranjan} title="Niranjan Janardhanan" desc="Meet Niranjan Janardhanan, our Treasurer for External Affairs! He’s passionate about robotics and loves the = button on his calculator for finding answers. He’s eyeing the LEGO Technic McLaren and enjoys catching up on sleep or watching YouTube." role="Treasurer of External Affairs"/>
              </div>
            <div className='col'>
              <SponsorCard logo={Navya} title="Navya Manni" desc="Navya Manni is our Lead Mentor who loves the opportunities and experiences the WHS Robotics club offers. Her favorite LEGO set is the NASA Artemis Space Launch System. Her favorite button on the calculator is the clear all button, and she enjoys reading, hanging out with friends, or catching up on sleep in her free time." role="Head FLL Mentor"/>
              <SponsorCard logo={Sahil} title="Sahil Julka" desc="Sahil Julka is our Head FLL Mentor with a passion for introducing people to the world of engineering. His favorite LEGO set is the Saturn V, and his favorite button on the calculator is the exponent button. When he’s not mentoring, he spends his time studying, playing sports, or playing games." role="Head FLL Mentor"/>
              <SponsorCard logo={Luv} title="Luv Kumar" desc="Meet Luv Kumar, our Director of Electrical Engineering! He's all about building and the joy of something functioning after working on it for a long time. When he's not working or building, he likes to read manga, watch anime, and scroll through social media." role="Director of Engineering"/>
            </div>
            <div className='col'>
              <SponsorCard logo={Alexander} title="Alexander Cheng" desc="Meet Alex Cheng, our Director of Educational Outreach! He loves building and also the club's new expedition, Intellikits! When he's not busy, he plays basketball of dives into rounds of Fortnite." role="Director of Educational Outreach"/>
              <SponsorCard logo={Ash} title="Ash Profeta" desc="Meet Anne-Beatrix Profeta, our Director of Activities! She’s all about outreach and meeting new people—it’s what makes her tick. When she’s not organizing awesome events, you’ll find her chilling with a book, napping, or enjoying a walk with some good tunes." role="Director of Activities"/>
              <SponsorCard logo={Millan} title="Millan Hamilton" desc="Meet Millan Hamilton, our Director of Technology! He’s all about learning, from STEM and programming to leadership. When he’s not diving into AI or building cool stuff with LEGO, he’s binge-watching Breaking Bad or playing chess." role="Director of Technology"/>
            </div>
            <div className='col'>
              <SponsorCard logo={Joseph} title="Jeongseop Yoon" desc="Jeongseop Yoon is one of our Co-Directors of Publicity who loves interacting with robotics parts. Despite being a builder, he enjoys programming because it is enjoyable to observe the robot function based on his input. His favorite LEGO set is the NASA Apollo Saturn V Space Rocket and Vehicles. His favorite button on the calculator is the back/delete button, and he plays the guitar in his free time." role="Director of Publicity"/>
              <SponsorCard logo={Bryan} title="Bryan Hsu" desc="Bryan Hsu is the other Co-Director of Publicity who loves the opportunities and the community in robotics. Like Jeongseop, he is also a builder, but likes programming. His favorite LEGO set is the McLaren Formula 1 Race Car. His favorite button on the calculator is the equal button, and he enjoys tinkering in his free time." role="Director of Publicity"/>
              <SponsorCard logo={Nathaniel} title="Nathaniel Soco" desc="Nathaniel Soco is a builder that loves the home meetings at robotics. His favorite lego set is the McLaren P1™. His favorite button on the calculator is the Off button, and he sleeps and plays video games in his free time." role="FLL Mentor"/>
            </div>
            <div className='col'>
              <SponsorCard logo={Omkar} title="Omkar Siddeswaran" desc="Omkar Siddeswaran is a programmer that loves publicity and the sense of family in robotics. His favorite lego set is the Koenigsegg Jesko Absolut White Hypercar. His favorite button on the calculator is the Rad button. He spends his free time watching TV, reading, or playing with his dog." role="FLL Mentor"/>
              <SponsorCard logo={Brandon} title="Brandon Chen" desc="Brandon Chen is a builder that loves talking and working with people. His favorite lego set is the NASA Space Shuttle Discovery. His favorite button on the calculator is the On button, and he reads in his free time." role="FLL Mentor"/>
            </div>
          </div>
          <div className='flex-grid mobile2' data-aos="fade-up">
            <h2>Club Leadership Team</h2>
            <div className='col'>
            <SponsorCard logo={Siddhant} title="Siddhant Khapra" desc="Meet Siddhant Khapra, our Co-President! He loves tackling new challenges and has picked up a bunch of cool skills. His favorite button on the calculator is the simplification button, and he’s eyeing the LEGO Concorde. When he’s not into robotics, Siddhant enjoys learning, playing tennis, trying new foods, and hanging out with friends." role="Co-President"/>
            <SponsorCard logo={Akshita} title="Akshita Shivakumar" desc="Say hi to Akshita Shivakumar, our President and FTC Captain! She’s all about the amazing resources we have, from tech to mentors. Her favorite feature is the off button, and she’s keen on the Ship in a Bottle 92177. In her free time, Akshita loves CAD design, reading, rock climbing, and Minecraft." role="Co-President"/>
            </div>
            <div className='col'>
            <SponsorCard logo={Mohnish} title="Mohnish Sivakumar" desc="Meet Mohnish Sivakumar, our Co-Secretary! He loves collaborating with his peers and innovating. His favorite button on a calculator is the natural log button, and his favorite LEGO set is Nano Gauntlet. In his free time, he watches K-dramas and K-pop and sings." role="Co-Vice President"/>
            <SponsorCard logo={Adit} title="Adit Shah" desc="Meet Adit Shah, our other Co-Secretary! His favorite thing about robotics club is the programs that allow students to appreciate STEM. His favorite button on the calculator is pi, and his favorite LEGO set is the McLaren P1. In his free time, he listens to music, plays volleyball, and hangs out with friends." role="Co-Vice Prsident"/>
            </div>
            <div className='col'>
            <SponsorCard logo={Mohana} title="Mohana Sai" desc="Meet Mohana Sai, our Treasurer for Internal Affairs! She’s excited by robotics for its creative potential and teaching kids. Her favorite button is EE, and she’s interested in the LEGO Technic McLaren or the Mercedes F1. In her free time, she plays with her dog, watches TV, enjoys boba, solves puzzles, or visits Disneyland." role="Secretary"/>
            <SponsorCard logo={Niranjan} title="Niranjan Janardhanan" desc="Meet Niranjan Janardhanan, our Treasurer for External Affairs! He’s passionate about robotics and loves the = button on his calculator for finding answers. He’s eyeing the LEGO Technic McLaren and enjoys catching up on sleep or watching YouTube." role="Secretary"/>
            </div>
            <div className='col'>
            <SponsorCard logo={Navya} title="Navya Manni" desc="Navya Manni is our Lead Mentor who loves the opportunities and experiences the WHS Robotics club offers. Her favorite LEGO set is the NASA Artemis Space Launch System. Her favorite button on the calculator is the clear all button, and she enjoys reading, hanging out with friends, or catching up on sleep in her free time." role="Head FLL Mentor"/>
            <SponsorCard logo={Sahil} title="Sahil Julka" desc="Sahil Julka is our Head FLL Mentor with a passion for introducing people to the world of engineering. His favorite LEGO set is the Saturn V, and his favorite button on the calculator is the exponent button. When he’s not mentoring, he spends his time studying, playing sports, or playing games." role="Head FLL Mentor"/>
            </div>
            <div className='col'>
            <SponsorCard logo={Luv} title="Luv Kumar" desc="Meet Luv Kumar, our Director of Electrical Engineering! He's all about building and the joy of something functioning after working on it for a long time. When he's not working or building, he likes to read manga, watch anime, and scroll through social media." role="Director of Engineering"/>
            <SponsorCard logo={Alexander} title="Alexander Cheng" desc="Meet Alex Cheng, our Director of Educational Outreach! He loves building and also the club's new expedition, Intellikits! When he's not busy, he plays basketball of dives into rounds of Fortnite." role="Director of Educational Outreach"/>
            </div>
            <div className='col'>
            <SponsorCard logo={Ash} title="Ash Profeta" desc="Meet Anne-Beatrix Profeta, our Director of Activities! She’s all about outreach and meeting new people—it’s what makes her tick. When she’s not organizing awesome events, you’ll find her chilling with a book, napping, or enjoying a walk with some good tunes." role="Director of Activities"/>
            <SponsorCard logo={Millan} title="Millan Hamilton" desc="Meet Millan Hamilton, our Director of Technology! He’s all about learning, from STEM and programming to leadership. When he’s not diving into AI or building cool stuff with LEGO, he’s binge-watching Breaking Bad or playing chess." role="Director of Technology"/>
            </div>
            <div className='col'>
            <SponsorCard logo={Joseph} title="Jeongseop Yoon" desc="Jeongseop Yoon is one of our Co-Directors of Publicity who loves interacting with robotics parts. Despite being a builder, he enjoys programming because it is enjoyable to observe the robot function based on his input. His favorite LEGO set is the NASA Apollo Saturn V Space Rocket and Vehicles. His favorite button on the calculator is the back/delete button, and he plays the guitar in his free time." role="Director of Publicity"/>
            <SponsorCard logo={Bryan} title="Bryan Hsu" desc="Bryan Hsu is the other Co-Director of Publicity who loves the opportunities and the community in robotics. Like Jeongseop, he is also a builder, but likes programming. His favorite LEGO set is the McLaren Formula 1 Race Car. His favorite button on the calculator is the equal button, and he enjoys tinkering in his free time." role="Director of Publicity"/>
            </div>
            <div className='col'>
            <SponsorCard logo={Nathaniel} title="Nathaniel Soco" desc="Nathaniel Soco is a builder that loves the home meetings at robotics. His favorite lego set is the McLaren P1™. His favorite button on the calculator is the Off button, and he sleeps and plays video games in his free time." role="FLL Mentor"/>
            <SponsorCard logo={Omkar} title="Omkar Siddeswaran" desc="Omkar Siddeswaran is a programmer that loves publicity and the sense of family in robotics. His favorite lego set is the Koenigsegg Jesko Absolut White Hypercar. His favorite button on the calculator is the Rad button. He spends his free time watching TV, reading, or playing with his dog." role="FLL Mentor"/>
            </div>
            <div className='col'>
            <SponsorCard logo={Brandon} title="Brandon Chen" desc="Brandon Chen is a builder that loves talking and working with people. His favorite lego set is the NASA Space Shuttle Discovery. His favorite button on the calculator is the On button, and he reads in his free time." role="FLL Mentor"/>
            </div>
          </div>
          <div className='flex-grid mobile3' data-aos="fade-up">
            
            <h2>Club Leadership Team</h2>
            <SponsorCard logo={Siddhant} title="Siddhant Khapra" desc="Meet Siddhant Khapra, our Co-President! He loves tackling new challenges and has picked up a bunch of cool skills. His favorite button on the calculator is the simplification button, and he’s eyeing the LEGO Concorde. When he’s not into robotics, Siddhant enjoys learning, playing tennis, trying new foods, and hanging out with friends." role="Co-President"/>
            <SponsorCard logo={Akshita} title="Akshita Shivakumar" desc="Say hi to Akshita Shivakumar, our President and FTC Captain! She’s all about the amazing resources we have, from tech to mentors. Her favorite feature is the off button, and she’s keen on the Ship in a Bottle 92177. In her free time, Akshita loves CAD design, reading, rock climbing, and Minecraft." role="Co-President"/>

            <SponsorCard logo={Mohnish} title="Mohnish Sivakumar" desc="Meet Mohnish Sivakumar, our Co-Secretary! He loves collaborating with his peers and innovating. His favorite button on a calculator is the natural log button, and his favorite LEGO set is Nano Gauntlet. In his free time, he watches K-dramas and K-pop and sings." role="Secretary"/>
            <SponsorCard logo={Adit} title="Adit Shah" desc="Meet Adit Shah, our other Co-Secretary! His favorite thing about robotics club is the programs that allow students to appreciate STEM. His favorite button on the calculator is pi, and his favorite LEGO set is the McLaren P1. In his free time, he listens to music, plays volleyball, and hangs out with friends." role="Secretary"/>

            <SponsorCard logo={Mohana} title="Mohana Sai" desc="Meet Mohana Sai, our Treasurer for Internal Affairs! She’s excited by robotics for its creative potential and teaching kids. Her favorite button is EE, and she’s interested in the LEGO Technic McLaren or the Mercedes F1. In her free time, she plays with her dog, watches TV, enjoys boba, solves puzzles, or visits Disneyland." role="Treasurer of Internal Affairs"/>
            <SponsorCard logo={Niranjan} title="Niranjan Janardhanan" desc="Meet Niranjan Janardhanan, our Treasurer for External Affairs! He’s passionate about robotics and loves the = button on his calculator for finding answers. He’s eyeing the LEGO Technic McLaren and enjoys catching up on sleep or watching YouTube." role="Treasurer of External Affairs"/>
            
            <SponsorCard logo={Navya} title="Navya Manni" desc="Navya Manni is our Lead Mentor who loves the opportunities and experiences the WHS Robotics club offers. Her favorite LEGO set is the NASA Artemis Space Launch System. Her favorite button on the calculator is the clear all button, and she enjoys reading, hanging out with friends, or catching up on sleep in her free time." role="Head FLL Mentor"/>
            <SponsorCard logo={Sahil} title="Sahil Julka" desc="Sahil Julka is our Head FLL Mentor with a passion for introducing people to the world of engineering. His favorite LEGO set is the Saturn V, and his favorite button on the calculator is the exponent button. When he’s not mentoring, he spends his time studying, playing sports, or playing games." role="Head FLL Mentr"/>

            <SponsorCard logo={Luv} title="Luv Kumar" desc="Meet Luv Kumar, our Director of Electrical Engineering! He's all about building and the joy of something functioning after working on it for a long time. When he's not working or building, he likes to read manga, watch anime, and scroll through social media." role="Director of Engineering"/>
            <SponsorCard logo={Alexander} title="Alexander Cheng" desc="Meet Alex Cheng, our Director of Educational Outreach! He loves building and also the club's new expedition, Intellikits! When he's not busy, he plays basketball of dives into rounds of Fortnite." role="Director of Educational Outreach"/>

            <SponsorCard logo={Ash} title="Ash Profeta" desc="Meet Anne-Beatrix Profeta, our Director of Activities! She’s all about outreach and meeting new people—it’s what makes her tick. When she’s not organizing awesome events, you’ll find her chilling with a book, napping, or enjoying a walk with some good tunes." role="Director of Activities"/>
            <SponsorCard logo={Millan} title="Millan Hamilton" desc="Meet Millan Hamilton, our Director of Technology! He’s all about learning, from STEM and programming to leadership. When he’s not diving into AI or building cool stuff with LEGO, he’s binge-watching Breaking Bad or playing chess." role="Director of Technology"/>

            <SponsorCard logo={Joseph} title="Jeongseop Yoon" desc="Jeongseop Yoon is one of our Co-Directors of Publicity who loves interacting with robotics parts. Despite being a builder, he enjoys programming because it is enjoyable to observe the robot function based on his input. His favorite LEGO set is the NASA Apollo Saturn V Space Rocket and Vehicles. His favorite button on the calculator is the back/delete button, and he plays the guitar in his free time." role="Director of Publicity"/>
            <SponsorCard logo={Bryan} title="Bryan Hsu" desc="Bryan Hsu is the other Co-Director of Publicity who loves the opportunities and the community in robotics. Like Jeongseop, he is also a builder, but likes programming. His favorite LEGO set is the McLaren Formula 1 Race Car. His favorite button on the calculator is the equal button, and he enjoys tinkering in his free time." role="Director of Publicity"/>

            <SponsorCard logo={Nathaniel} title="Nathaniel Soco" desc="Nathaniel Soco is a builder that loves the home meetings at robotics. His favorite lego set is the McLaren P1™. His favorite button on the calculator is the Off button, and he sleeps and plays video games in his free time." role="FLL Mentor"/>
            <SponsorCard logo={Omkar} title="Omkar Siddeswaran" desc="Omkar Siddeswaran is a programmer that loves publicity and the sense of family in robotics. His favorite lego set is the Koenigsegg Jesko Absolut White Hypercar. His favorite button on the calculator is the Rad button. He spends his free time watching TV, reading, or playing with his dog." role="FLL Mentor"/>

            <SponsorCard logo={Brandon} title="Brandon Chen" desc="Brandon Chen is a builder that loves talking and working with people. His favorite lego set is the NASA Space Shuttle Discovery. His favorite button on the calculator is the On button, and he reads in his free time." role="FLL Mentor"/>
          </div>
          <div className='advisors'>
            <h2>Advisors</h2>
            <p>Matthew Johnson | Steven Ou | Kimberly Suzuki | Eamon Marchant | Paul Bender</p>
          </div>
      </div>
    </>
  )
}

export default Home