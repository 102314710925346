import React from 'react'
import './Styles/Sponsor.scss'
import SponsorCard from '../Components/SponsorCard/index.js'

import Raytheon from '../Assets/Sponsors/Raytheon.png'
import Boeing from '../Assets/Sponsors/Boeing.png'
import Disney from '../Assets/Sponsors/Disney.png'
import HFT from '../Assets/Sponsors/Harbor-Freight-Tools.png'
import Rev from '../Assets/Sponsors/Rev.png'
import RobotZone from '../Assets/Sponsors/RobotZone.png'
import SolidWorks from '../Assets/Sponsors/Solidworks.png'
import VMWare from '../Assets/Sponsors/VMWare.png'
import Accompli from '../Assets/Sponsors/Accompli.png'
import Panasonic from '../Assets/Sponsors/panasonic.jpg'
import Whitney from '../Assets/Sponsors/Whitney.png'
import GoBilda from '../Assets/Sponsors/goBILDA.svg'
import Wonderful from '../Assets/Sponsors/Wonderful.jpeg'
import AndyMark from '../Assets/Sponsors/andymark.png'
import SpeedyMetals from '../Assets/Sponsors/speedy.png'
import First from '../Assets/Sponsors/FIRST.jpeg'
import QualComm from '../Assets/Sponsors/qualcomm.png'
import TeamSprocket from '../Assets/Sponsors/TeamSprocket.png'

import JoinGraphic from '../Assets/Sponsors/PageContent/JoinGraphic.png'

import { Link } from 'react-router-dom'

const Sponsors = () => {
  return (
    <>
      <div className='sponsor-header'>
        <h1>Our Supporters</h1>
        <h2>Huge thank you to all of our corporate sponsors!</h2>
      </div>
      <div className='sponsor-footer'>
        <div className='column'>
          <h1>Want to join this list?</h1>
          <p>Thanks to our supporters, we are able to host community service activities and participate in a variety of robotics competitions throughout the year! Your donations go a long way in helping us make an impact in our community. At WHS Robotics we strive to be a source of knowledge and inspiration towards students all around our local community, and we are sure to represent are sponsors and spread their brand everywhere we go. If this is something that interests you or your company please contact us for more information!
          </p>
          <a href='mailto:sponsorships@whitneyrobotics.org'>Email us!</a>
        </div>
        <img src={JoinGraphic} alt="JoinGraphic" className='graphic'/>
      </div>
      <h1 className='sponsor-instructions'>Hover over each sponsor card to learn more!</h1>
      <div className='sponsor-container web'>
        <div className='row'>
        <SponsorCard logo={VMWare} site="https://www.vmware.com/" title="VMWare" desc="VMware vSphere, also referred to as a cloud operating system or a virtualized data center platform, enables IT departments to place application workloads on the most cost-effective compute resource available" />
        <SponsorCard logo={Raytheon} site="https://www.rtx.com/" title="Raytheon Technologies" desc="Raytheon Technologies Corporation is an aerospace and defense company that provides advanced systems and services for commercial, military and government customers worldwide." />
        <SponsorCard logo={Boeing} site="https://www.boeing.com/" title="Boeing" desc="As a leading global aerospace company, Boeing develops, manufactures and services commercial airplanes, defense products and space systems for customers in more than 150 countries." />
        <SponsorCard logo={Disney} site="https://www.disney.com/" title="Disney" desc="The mission of The Walt Disney Company is to entertain, inform and inspire people around the globe through the power of unparalleled storytelling reflecting the iconic brands, creative minds and innovative technologies." />
        </div>
        <div className='row'>
        <SponsorCard logo={Accompli} site="https://accomplicenter.com/" title="Accompli Center" desc="AccompliCenter offers a place for your business to call home. We have HR services, mailbox rental, packing and shipping, and a conference center. Best of all, we offer the experience and guidance you'll need to accomplish your business." />
        <SponsorCard logo={SolidWorks} site="https://www.solidworks.com/" title="SOLIDWORKS" desc="Dassault Systèmes provides business and people with virtual universes to imagine sustainable innovations capable of harmonizing product, nature and life." />
        <SponsorCard logo={RobotZone} site="https://www.solidworks.com/" title="RobotZone" desc="We are a team of people who are passionate about making awesome things, and dedicated to creating precision mechanical components that help you turn your ideas into a reality." />
        <SponsorCard logo={Rev} site="https://www.revrobotics.com/" title="REV Robotics" desc="Founded in 2014 by Greg Needel and David Yanoshak, two friends with a passion for robots – together they created REV, helping students around the world realize their full robotic design potential." />

        </div>
        <div className='row'>
        <SponsorCard logo={HFT} site="https://www.harborfreight.com/" title="Harbor Freight Tools" desc="A privately held tool and equipment retailer, headquartered in Calabasas, California, which operates a chain of retail stores, as well as a mail-order and e-commerce business." />
        <SponsorCard logo={Panasonic} site="https://shop.panasonic.com/" title="Panasonic" desc="Panasonic is a multinational electronics corporation known for producing a wide range of consumer and industrial products, including electronics, appliances, and automotive components." />
        <SponsorCard logo={Whitney} site="https://www.whitneyhs.us/" title="Whitney High School" desc="The Whitney High School Foundation for Educational Excellence (WHSFEE) was founded in 1989 as a non-profit parents' organization with the purpose to promote and support the educational needs of the students of Whitney High School." />
        <SponsorCard logo={GoBilda} site="https://www.gobilda.com/" title="GoBilda" desc="GoBilda is a company specializing in providing a versatile and high-quality hardware and robotics construction system designed for educational and engineering purposes." />
        </div>
        <div className='row'>
        <SponsorCard logo={Wonderful} site="https://www.wonderful.com/" title="Wonderful" desc="The Wonderful Company is a privately held American multinational corporation known for its diverse portfolio of consumer goods and agricultural products, including brands like Wonderful Pistachios, Wonderful Halos, and POM Wonderful." />
        <SponsorCard logo={AndyMark} site="https://www.andymark.com/" title="AndyMark" desc="AndyMark is a company that specializes in providing robotics and engineering products, particularly in the field of robotics competitions, offering a wide range of components and resources to support teams in designing and building competitive robots." />
        <SponsorCard logo={SpeedyMetals} site="https://www.speedymetals.com/" title="Speedy Metals" desc="Speedy Metals is an online metal supplier that offers a wide selection of metals, including steel, aluminum, and stainless steel, providing customers with a convenient platform to purchase various metal products for both personal and industrial applications." />
        <SponsorCard logo={First} site="https://www.firstinspires.org/" title="The Kamen Family" desc="The Kamen family founded FIRST, inspiring youth in the fields of Science, Technology, Engineering, and Mathematics (STEM) , with a vision to create future innovators and problem solvers." />

        </div>
        <div className='row'>
        <SponsorCard logo={QualComm} site="https://www.qualcomm.com/" title="Qualcomm" desc="Qualcomm is a leading semiconductor and telecommunications company known for its innovations in wireless technology and the development of mobile chipsets, enabling the proliferation of smartphones and advanced wireless connectivity." />
        <SponsorCard logo={TeamSprocket} site="https://www.team3473.com/" title="Team Sprocket" desc="Team Sprocket's hope to provide an interdisciplinary education for students with the learning of industry-applicable technical engineering skills as well as entrepreneurship skills through community involvement and business sponsorships." />

        </div>
      </div>
      <div className='sponsor-container mobile1'>
        <div className='row'>
        <SponsorCard logo={VMWare} site="https://www.vmware.com/" title="VMWare" desc="VMware vSphere, also referred to as a cloud operating system or a virtualized data center platform, enables IT departments to place application workloads on the most cost-effective compute resource available" />
        <SponsorCard logo={Disney} site="https://www.disney.com/" title="Disney" desc="The mission of The Walt Disney Company is to entertain, inform and inspire people around the globe through the power of unparalleled storytelling reflecting the iconic brands, creative minds and innovative technologies." />
        <SponsorCard logo={Boeing} site="https://www.boeing.com/" title="Boeing" desc="As a leading global aerospace company, Boeing develops, manufactures and services commercial airplanes, defense products and space systems for customers in more than 150 countries." />
        </div>
        <div className='row'>
        <SponsorCard logo={Rev} site="https://www.revrobotics.com/" title="REV Robotics" desc="Founded in 2014 by Greg Needel and David Yanoshak, two friends with a passion for robots – together they created REV, helping students around the world realize their full robotic design potential." />
        <SponsorCard logo={Accompli} site="https://accomplicenter.com/" title="Accompli Center" desc="AccompliCenter offers a place for your business to call home. We have HR services, mailbox rental, packing and shipping, and a conference center. Best of all, we offer the experience and guidance you'll need to accomplish your business." />
        <SponsorCard logo={HFT} site="https://www.harborfreight.com/" title="Harbor Freight Tools" desc="A privately held tool and equipment retailer, headquartered in Calabasas, California, which operates a chain of retail stores, as well as a mail-order and e-commerce business." />
        </div>
        <div className='row'>
        <SponsorCard logo={SolidWorks} site="https://www.solidworks.com/" title="SOLIDWORKS" desc="Dassault Systèmes provides business and people with virtual universes to imagine sustainable innovations capable of harmonizing product, nature and life." />
        <SponsorCard logo={Panasonic} site="https://shop.panasonic.com/" title="Panasonic" desc="Panasonic is a multinational electronics corporation known for producing a wide range of consumer and industrial products, including electronics, appliances, and automotive components." />
        <SponsorCard logo={Raytheon} site="https://www.rtx.com/" title="Raytheon Technologies" desc="Raytheon Technologies Corporation is an aerospace and defense company that provides advanced systems and services for commercial, military and government customers worldwide." />

        </div>
        <div className='row'>
        <SponsorCard logo={Whitney} site="https://www.whitneyhs.us/" title="Whitney High School" desc="The Whitney High School Foundation for Educational Excellence (WHSFEE) was founded in 1989 as a non-profit parents' organization with the purpose to promote and support the educational needs of the students of Whitney High School." />
        <SponsorCard logo={GoBilda} site="https://www.gobilda.com/" title="GoBilda" desc="GoBilda is a company specializing in providing a versatile and high-quality hardware and robotics construction system designed for educational and engineering purposes." />
        <SponsorCard logo={Wonderful} site="https://www.wonderful.com/" title="Wonderful" desc="The Wonderful Company is a privately held American multinational corporation known for its diverse portfolio of consumer goods and agricultural products, including brands like Wonderful Pistachios, Wonderful Halos, and POM Wonderful." />
        </div>
        <div className='row'>
        <SponsorCard logo={AndyMark} site="https://www.andymark.com/" title="AndyMark" desc="AndyMark is a company that specializes in providing robotics and engineering products, particularly in the field of robotics competitions, offering a wide range of components and resources to support teams in designing and building competitive robots." />
        <SponsorCard logo={SpeedyMetals} site="https://www.speedymetals.com/" title="Speedy Metals" desc="Speedy Metals is an online metal supplier that offers a wide selection of metals, including steel, aluminum, and stainless steel, providing customers with a convenient platform to purchase various metal products for both personal and industrial applications." />
        <SponsorCard logo={First} site="https://www.firstinspires.org/" title="The Kamen Family" desc="The Kamen family founded FIRST, inspiring youth in the fields of Science, Technology, Engineering, and Mathematics (STEM) , with a vision to create future innovators and problem solvers." />

        </div>
        <div className='row'>
        <SponsorCard logo={QualComm} site="https://www.qualcomm.com/" title="Qualcomm" desc="Qualcomm is a leading semiconductor and telecommunications company known for its innovations in wireless technology and the development of mobile chipsets, enabling the proliferation of smartphones and advanced wireless connectivity." />
        <SponsorCard logo={TeamSprocket} site="https://www.team3473.com/" title="Team Sprocket" desc="Team Sprocket's hope to provide an interdisciplinary education for students with the learning of industry-applicable technical engineering skills as well as entrepreneurship skills through community involvement and business sponsorships." />

        </div>
      </div>
      <div className="sponsor-container mobile2">
        <div className='row'>
        <SponsorCard logo={VMWare} site="https://www.vmware.com/" title="VMWare" desc="VMware vSphere, also referred to as a cloud operating system or a virtualized data center platform, enables IT departments to place application workloads on the most cost-effective compute resource available" />
        <SponsorCard logo={Raytheon} site="https://www.rtx.com/" title="Raytheon Technologies" desc="Raytheon Technologies Corporation is an aerospace and defense company that provides advanced systems and services for commercial, military and government customers worldwide." />
        </div>
        <div className='row'>
        <SponsorCard logo={Disney} site="https://www.disney.com/" title="Disney" desc="The mission of The Walt Disney Company is to entertain, inform and inspire people around the globe through the power of unparalleled storytelling reflecting the iconic brands, creative minds and innovative technologies." />
        <SponsorCard logo={HFT} site="https://www.harborfreight.com/" title="Harbor Freight Tools" desc="A privately held tool and equipment retailer, headquartered in Calabasas, California, which operates a chain of retail stores, as well as a mail-order and e-commerce business." />
        </div>
        <div className='row'>
        <SponsorCard logo={Rev} site="https://www.revrobotics.com/" title="REV Robotics" desc="Founded in 2014 by Greg Needel and David Yanoshak, two friends with a passion for robots – together they created REV, helping students around the world realize their full robotic design potential." />
        <SponsorCard logo={Accompli} site="https://accomplicenter.com/" title="Accompli Center" desc="AccompliCenter offers a place for your business to call home. We have HR services, mailbox rental, packing and shipping, and a conference center. Best of all, we offer the experience and guidance you'll need to accomplish your business." />
        </div>
        <div className='row'>
        <SponsorCard logo={SolidWorks} site="https://www.solidworks.com/" title="SOLIDWORKS" desc="Dassault Systèmes provides business and people with virtual universes to imagine sustainable innovations capable of harmonizing product, nature and life." />
        <SponsorCard logo={Boeing} site="https://www.boeing.com/" title="Boeing" desc="As a leading global aerospace company, Boeing develops, manufactures and services commercial airplanes, defense products and space systems for customers in more than 150 countries." />

        </div>
        <div className='row'>
        <SponsorCard logo={Panasonic} site="https://shop.panasonic.com/" title="Panasonic" desc="Panasonic is a multinational electronics corporation known for producing a wide range of consumer and industrial products, including electronics, appliances, and automotive components." />
        <SponsorCard logo={Whitney} site="https://www.whitneyhs.us/" title="Whitney High School" desc="The Whitney High School Foundation for Educational Excellence (WHSFEE) was founded in 1989 as a non-profit parents' organization with the purpose to promote and support the educational needs of the students of Whitney High School." />

        </div>
        <div className='row'>
        <SponsorCard logo={GoBilda} site="https://www.gobilda.com/" title="GoBilda" desc="GoBilda is a company specializing in providing a versatile and high-quality hardware and robotics construction system designed for educational and engineering purposes." />
        <SponsorCard logo={Wonderful} site="https://www.wonderful.com/" title="Wonderful" desc="The Wonderful Company is a privately held American multinational corporation known for its diverse portfolio of consumer goods and agricultural products, including brands like Wonderful Pistachios, Wonderful Halos, and POM Wonderful." />

        </div>
        <div className='row'>
        <SponsorCard logo={AndyMark} site="https://www.andymark.com/" title="AndyMark" desc="AndyMark is a company that specializes in providing robotics and engineering products, particularly in the field of robotics competitions, offering a wide range of components and resources to support teams in designing and building competitive robots." />
        <SponsorCard logo={SpeedyMetals} site="https://www.speedymetals.com/" title="Speedy Metals" desc="Speedy Metals is an online metal supplier that offers a wide selection of metals, including steel, aluminum, and stainless steel, providing customers with a convenient platform to purchase various metal products for both personal and industrial applications." />

        </div>
        <div className='row'>
        <SponsorCard logo={First} site="https://www.firstinspires.org/" title="The Kamen Family" desc="The Kamen family founded FIRST, inspiring youth in the fields of Science, Technology, Engineering, and Mathematics (STEM) , with a vision to create future innovators and problem solvers." />
        <SponsorCard logo={QualComm} site="https://www.qualcomm.com/" title="Qualcomm" desc="Qualcomm is a leading semiconductor and telecommunications company known for its innovations in wireless technology and the development of mobile chipsets, enabling the proliferation of smartphones and advanced wireless connectivity." />

        </div>
        <div className='row'>
        <SponsorCard logo={TeamSprocket} site="https://www.team3473.com/" title="Team Sprocket" desc="Team Sprocket's hope to provide an interdisciplinary education for students with the learning of industry-applicable technical engineering skills as well as entrepreneurship skills through community involvement and business sponsorships." />

        </div>

      </div>
      <div className="sponsor-container mobile3">
      <SponsorCard logo={VMWare} site="https://www.vmware.com/" title="VMWare" desc="VMware vSphere, also referred to as a cloud operating system or a virtualized data center platform, enables IT departments to place application workloads on the most cost-effective compute resource available" />
        <SponsorCard logo={Raytheon} site="https://www.rtx.com/" title="Raytheon Technologies" desc="Raytheon Technologies Corporation is an aerospace and defense company that provides advanced systems and services for commercial, military and government customers worldwide." />
        <SponsorCard logo={Boeing} site="https://www.boeing.com/" title="Boeing" desc="As a leading global aerospace company, Boeing develops, manufactures and services commercial airplanes, defense products and space systems for customers in more than 150 countries." />
        <SponsorCard logo={Disney} site="https://www.disney.com/" title="Disney" desc="The mission of The Walt Disney Company is to entertain, inform and inspire people around the globe through the power of unparalleled storytelling reflecting the iconic brands, creative minds and innovative technologies." />
        <SponsorCard logo={HFT} site="https://www.harborfreight.com/" title="Harbor Freight Tools" desc="A privately held tool and equipment retailer, headquartered in Calabasas, California, which operates a chain of retail stores, as well as a mail-order and e-commerce business." />
        <SponsorCard logo={Rev} site="https://www.revrobotics.com/" title="REV Robotics" desc="Founded in 2014 by Greg Needel and David Yanoshak, two friends with a passion for robots – together they created REV, helping students around the world realize their full robotic design potential." />
        <SponsorCard logo={Accompli} site="https://accomplicenter.com/" title="Accompli Center" desc="AccompliCenter offers a place for your business to call home. We have HR services, mailbox rental, packing and shipping, and a conference center. Best of all, we offer the experience and guidance you'll need to accomplish your business." />
        <SponsorCard logo={SolidWorks} site="https://www.solidworks.com/" title="SOLIDWORKS" desc="Dassault Systèmes provides business and people with virtual universes to imagine sustainable innovations capable of harmonizing product, nature and life." />
        <SponsorCard logo={Panasonic} site="https://shop.panasonic.com/" title="Panasonic" desc="Panasonic is a multinational electronics corporation known for producing a wide range of consumer and industrial products, including electronics, appliances, and automotive components." />
        <SponsorCard logo={Whitney} site="https://www.whitneyhs.us/" title="Whitney High School" desc="The Whitney High School Foundation for Educational Excellence (WHSFEE) was founded in 1989 as a non-profit parents' organization with the purpose to promote and support the educational needs of the students of Whitney High School." />
        <SponsorCard logo={GoBilda} site="https://www.gobilda.com/" title="GoBilda" desc="GoBilda is a company specializing in providing a versatile and high-quality hardware and robotics construction system designed for educational and engineering purposes." />
        <SponsorCard logo={Wonderful} site="https://www.wonderful.com/" title="Wonderful" desc="The Wonderful Company is a privately held American multinational corporation known for its diverse portfolio of consumer goods and agricultural products, including brands like Wonderful Pistachios, Wonderful Halos, and POM Wonderful." />
        <SponsorCard logo={AndyMark} site="https://www.andymark.com/" title="AndyMark" desc="AndyMark is a company that specializes in providing robotics and engineering products, particularly in the field of robotics competitions, offering a wide range of components and resources to support teams in designing and building competitive robots." />
        <SponsorCard logo={SpeedyMetals} site="https://www.speedymetals.com/" title="Speedy Metals" desc="Speedy Metals is an online metal supplier that offers a wide selection of metals, including steel, aluminum, and stainless steel, providing customers with a convenient platform to purchase various metal products for both personal and industrial applications." />
        <SponsorCard logo={First} site="https://www.firstinspires.org/" title="The Kamen Family" desc="The Kamen family founded FIRST, inspiring youth in the fields of Science, Technology, Engineering, and Mathematics (STEM) , with a vision to create future innovators and problem solvers." />
        <SponsorCard logo={QualComm} site="https://www.qualcomm.com/" title="Qualcomm" desc="Qualcomm is a leading semiconductor and telecommunications company known for its innovations in wireless technology and the development of mobile chipsets, enabling the proliferation of smartphones and advanced wireless connectivity." />
        <SponsorCard logo={TeamSprocket} site="https://www.team3473.com/" title="Team Sprocket" desc="Team Sprocket's hope to provide an interdisciplinary education for students with the learning of industry-applicable technical engineering skills as well as entrepreneurship skills through community involvement and business sponsorships." />

      </div>
    </>
  )
}

export default Sponsors