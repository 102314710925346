import React from 'react'
import './Styles/RPEX.scss'
import QRCode from '../Assets/SIAB/QRCode.png'
import ImageSlider from '../Components/ImageSlider/ImageSlider.js'
import {HashLink} from 'react-router-hash-link'
import Carousel from '../Components/Gallery/carousel.js'
import InfoCard from '../Components/InfoCard/index.js'
import VideoController from '../Components/VideoController/index.js'
import ContentPrompt from '../Components/ContentPrompt/index.js'

import AOS from 'aos';
import 'aos/dist/aos.css';
import logo from "../Assets/RPEX/rpex_logo.png"
import logo2 from "../Assets/RPEX/rpex_logo.png"
import RPEXVideo from '../Assets/RPEX/RPEX_Promo_final.mp4'
import IMG_1 from "../Assets/RPEX/IMG_1.JPG";
import IMG_2 from "../Assets/RPEX/IMG_2.JPG";
import IMG_3 from "../Assets/RPEX/IMG_3.JPG";
import IMG_4 from "../Assets/RPEX/IMG_4.JPG";
import IMG_5 from "../Assets/RPEX/IMG_5.JPG";
import IMG_6 from "../Assets/RPEX/IMG_6.JPG";
import IMG_7 from "../Assets/RPEX/IMG_7.JPG";
import IMG_8 from "../Assets/RPEX/IMG_8.JPG";
import Multi_Color_Header from "../Assets/RPEX/multicolor.png";
import beeWax from "../Assets/RPEX/beeWax.png";
import beeWax2 from "../Assets/RPEX/beeWax2.png";

const items = [
  { src: IMG_1, alt: '1' },
  { src: IMG_2, alt: '2' },
  { src: IMG_3, alt: '3' },
  { src: IMG_4, alt: '4' },
  { src: IMG_5, alt: '5' },
  { src: IMG_6, alt: '6' },
  { src: IMG_7, alt: '7' },
  { src: IMG_8, alt: '8' }
];
const first_color = '#23AAE1';
const second_color = '#ED1C24';
const background = '#F3F3F3';

AOS.init()

class RPEX extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {promptEnabled: false, promptName: null, faqEnabled: false}
    this.RPEXClick = this.RPEXClick.bind(this)

  }
  
  RPEXClick() {
    const promptEnabled = this.state.promptEnabled
    if (!promptEnabled) {
      this.setState({promptEnabled: true, promptName: "RPEX"})
    }
  }

  contentCallback = () => { console.log("callback called"); this.setState({promptEnabled: false}) }


  render() {

    let promptObject = (null);
    if (this.state.promptEnabled) {
      promptObject = <ContentPrompt name={this.state.promptName} callback={this.contentCallback}/>
    }

    AOS.init();

    return (
      <>
      <head>
      <link rel="preconnect" href="https://fonts.googleapis.com"/>
      <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin/>
      <link href="https://fonts.googleapis.com/css2?family=Lexend&family=Playfair+Display&family=Playfair+Display+SC:ital@1&display=swap" rel="stylesheet"></link>
      <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no" />
      </head>
        <div className='rpex-header'>
          <h1>Robotics Principles - Explore</h1>
          <h2>The RPEX program allows students to learn advanced programming and building skills through SPIKE Prime!</h2>
        </div >
        <div className="rpex-about">
        <img className = 'color_header' src ={Multi_Color_Header}></img>
          <h1>About RPEX</h1>
          </div>
          <img className = "beeWax2" src = {beeWax2}></img>

          <div className = "rpex-logo">
            <img src={logo} className ="rpex logo" data-aos="fade-right"/>
            </div>
            <div className="rpex-text" data-aos="fade-left">
              <p className="rpex-text1">The program introduces students to block-code programming. We believe that block-code is a perfect oppurtunity for beginner students to get a headstart in their programming journey!</p>
              <p className="rpex-text2">The curriculum is designed to provide a complete introduction to robotics through LEGO Spike Prime. Mentors are available to assist students frequently.</p>

            </div>
            <div className = "rpex-logo2">
            <img src={logo2} className="rpex logo2" data-aos="fade-right"/>
            </div>
            <div className='gallery_space'>
              <h1 className = "rpex_gallery">Our RPEX Gallery! </h1>
              
              <Carousel items = {items} firstDotColor={first_color} secondDotColor={second_color}/>
              <div className = "cards">
                <div className = "red first_card">
                <InfoCard  name="Admissions"  desc={`Student admission will be stratified per school. Schools have been chosen and provided seats based on the existence of Robotics or STEM programs, alumni of which can continue to practice their skills in this program, and the geographic location relative to Whitney. 
          
          The program will gather attendance from as many schools as possible, disregarding the community and socioeconomic background that the students come from. Financial Aid will be provided on an application basis. The link to the application is inside of the main program application portal.
          
          All applicants are expected to embody the traits and ideals of Whitney student candidates, as we intend for this program to give them a bit of insight to the Whitney campus life.
        `} bgColor={background}/>
              </div>
                <div className = 'red'>
                  <InfoCard name="Dates, Deadlines, and Program Times" desc ={"Applications will close at 11:59PM Pacific Time on December 2nd, 2023. RPEX is a robotics fundamentals program by Whitney High School Robotics. This program will take place on Whitney High School's campus on Tuesdays and Thursdays from 3:50 PM to 5:30 PM for a total of ten sessions encompasing five weeks. A fee of $189.99 is required, but selectees who need financial aid will be given the opportunity to receive such a discount on their tuition."} bgColor={background} bold= {true}/>
                </div>
                <img className = "beeWax" src = {beeWax}></img>
              <div class = "banner-btn2">
                  <button class = "btn2" onClick={this.RPEXClick}><span></span>Lesson Structure</button>
              </div>
            </div>
            </div>
                
          <div className='rpex-video'>
          <div className='video-section' data-aos="fade-up">
            <h1>RPEX Video</h1> <hr />
            <VideoController src={RPEXVideo} />
          </div>
          </div>
          {promptObject}

      </>
    )
  }

}

export default RPEX
