import React from 'react'
import './Styles/FTC.scss'

import VideoController from '../Components/VideoController/index.js'
import SponsorCard from '../Components/SponsorCard/index.js'
import ContentPrompt from "../Components/ContentPrompt/index.js"

import PFP from '../Assets/FTC/tempPFP.png'
import IntroVideo from '../Assets/FTC/IntroVideo.mp4'
import AOS from 'aos';
import 'aos/dist/aos.css';

import Logo from "../Assets/Logo.png"

AOS.init()

class FTC extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {promptEnabled: false, promptName: null, faqEnabled: false}
    this.OpenAwardCard = this.OpenAwardCard.bind(this)
    this.OpenMentorCard = this.OpenMentorCard.bind(this)

  }
  
  OpenAwardCard() {
    const promptEnabled = this.state.promptEnabled
    if (!promptEnabled) {
      this.setState({promptEnabled: true, promptName: "Awards"})
    }
  }

  OpenMentorCard() {
    const promptEnabled = this.state.promptEnabled
    if (!promptEnabled) {
      this.setState({promptEnabled: true, promptName: "Mentors"})
    }
  }

  contentCallback = () => { console.log("callback called"); this.setState({promptEnabled: false}) }


  render() {

    let promptObject = (null);
    if (this.state.promptEnabled) {
      promptObject = <ContentPrompt name={this.state.promptName} callback={this.contentCallback}/>
    }

    AOS.init();

    return (
      <>
        <div className='FTC-header'>
          <h1>First Tech Challenge</h1>
          <h2>Our high school competition team (Team 542)!</h2>
        </div>

        <div className='FTC-content'> 
          <div className='section1' data-aos="fade-up">
            <h1>Mission Statement</h1> <hr />
            <p className='mission'>Our team's mission is to compete and collaborate with other FIRST Tech Challenge teams to grow and learn together and to use our outreach to to provide STEM and Robotics opportunities to students who are economically, socially, and/or physically disadvantaged!</p>
            <VideoController src={IntroVideo} />
          </div>
          <div className='section3' data-aos="fade-up">
            <h1>Team History</h1>
            <br></br>
            <ul>
              <h3><b>2005-2006: Half-Pipe Hustle (Unofficial)</b></h3>
              <hr />
                <li>Cal State Northridge Regional Competition: Unofficial Participation</li>
                <li>2005 Los Angeles Regional Championship: Connect Award</li>
                <br />

              <h3><b>2006-2007: Hangin'-A-Round</b></h3>
              <hr />
                <li>2006 Los Angeles Regional Championship: Connect Award</li>
                <li>2006 Orange County Regional Championship: Connect Award</li>
                <li>2006 San Diego Scrimmage: Second Place Alliance</li>
                <br />

              <h3><b>2007-2008: Quad Quandary</b></h3>
              <hr />
                <li>2007 CSUDH Regional Qualifier: Finalist Alliance</li>
                <li>2007 LA County College Regional Qualifier: Winning Alliance, Connect Award</li>
                <li>2007 Los Angeles Regional Championship: Inspire Award</li>
                <li>2007 San Diego Regional Championship Tournament: Connect Award, Winning Alliance</li>
                <li>2008 FTC World Championship: Participated</li>
                <br />

              <h3><b>2008-2009: Face Off</b></h3>
              <hr />
                <li>2008 Los Angeles Regional Championship: PTC Design Award</li>
                <li>2008 San Diego Regional Championship: Participated</li>
                <br />

              <h3><b>2009-2010: Hot Shot</b></h3>
              <hr />
                <li>2010 Los Angeles Regional Championship: Inspire Award</li>
                <li>2010 FTC World Championship: Philosopher's Muse (Judge's Award)</li>
                <br />

              <h3><b>2010-2011: Get Over it!</b></h3>
              <hr />
                <li>Los Angeles Regional Championship: Second Place Inspire Award, Motivate Award, Connect Award Finalist, Think Award Finalist</li>
                <li>2011 FTC World Championship: Participated</li>
                <br />

              <h3><b>2011-2012: Bowled Over!</b></h3>
              <hr />
                <li>Los Angeles Regional Championship: Second Place Inspire Award, Motivate Award, Connect Award Finalist, Think Award Finalist</li>
                <li>2012 FTC World Championship: Participated</li>
                <br />

              <h3><b>2012-2013: Ring it Up!</b></h3>
              <hr />
                <li>2012 Claremont Qualifier (LA region): Inspire Award, Winning Alliance 1st Pick</li>
                <li>2013 Monrovia Qualifier (LA region): Winning Alliance 1st Pick, PTC Design Award</li>
                <li>2013 Los Angeles Regional Championship: Winning Alliance Captain, Promote Award</li>
                <li>2013 FTC World Championship: 12th in Franklin Division</li>
                <br />

              <h3><b>2013-2014: Block Party!</b></h3>
              <hr />
                <li>2013 San Diego Qualifier #1: Think Award, 3rd Place Inspire Award, 4th Seed Alliance</li>
                <li>2013 Claremont Qualifier (LA region): Winning Alliance Captain, Inspire Award</li>
                <li>2013 Downtown LA Qualifier (LA region): 3rd Place Inspire Award, 3rd Seed Alliance Captain, Control Award</li>
                <br />

              <h3><b>2014-2015: Cascade Effect</b></h3>
              <hr />
                  <li>San Diego Qualifiers: PTC Design Award</li>
                  <li>Claremont Qualifiers (LA Region): Winning Alliance &amp; Inspire Award</li>
                  <li>Perris Qualifiers: Promote Award, Winning Alliance, Think Award</li>
                  <li>Las Vegas Regionals: Winning Alliance &amp; Think Award</li>
                  <li>Los Angeles Regionals: Division Finalist &amp; Inspire Award</li>
                  <li>West Super Regionals: Division Finalist &amp; Inspire Award</li>
                  <li>World Championships: Rockwell Collins Innovate Award nominee</li>
                  <br></br>
              <h3><b>2015-2016: Res-Q</b></h3>
              <hr />
                  <li>2016 DeToledo Qualifier (LA Region): 2nd place Inspire Award, PTC Design Award, Promote Award, 2nd place Motivate</li>
                  <li>2016 Perris Qualifier (LA Region): Connect Award, 2nd place Promote</li>
                  <li>2016 Los Angeles Regional Championship: 3rd place Motivate</li>
                  <br></br>
              <h3><b>2016-2017: Velocity Vortex</b></h3>
              <hr />
                  <li>FLABOB Interleague: Think Award, 3rd Place Inspire Award, 3rd Place Connect Award, 3rd Place PTC Design Award, Promote Award</li>
                  <li>LA Regionals: First Place Think Award</li>
                  <br></br>
              <h3><b>2017-2018: Relic Recovery</b></h3>
              <hr />
                  <li>Palmdale A1&amp;I Interleague: Design, 2nd place Inspire Award, 3rd place Connect Award, 3rd Place Control Award</li>
                  <li>LA Regionals: 2nd Place Design Award</li>
                  <br></br>
              <h3><b>2018-2019: Rover Ruckus</b></h3>
              <hr />
                  <li>Monrovia A1&amp;C2 Interleague: Control Award, 2nd place Inspire Award, 2nd place Think Award, 2nd Place Design Award</li>
                  <li>LA Regionals: Think Award &amp; Promote Award</li>
                  <li>World Championships (Houston, TX): 46th/80 in Jemison</li>
                  <br></br>
              <h3><b>2019-2020: Skystone</b></h3>
              <hr />
                  <li>Palmdale A1&amp;D Interleague: Inspire Award, Promote Award, 2nd place Connect Award, 3rd place Design Award</li>
                  <li>LA Regionals: 2nd Place Inspire Award, Control Award, 3rd Place Think Award</li>
                  <li>Qualified for 2020 FTC World Championship (Canceled)</li>
                  <br></br>
              <h3><b>2020-2021:  Ultimate Goal</b></h3>
              <hr />
                  <li>LA Regionals: 1st Place Motivate Award, 2nd Place Control Award, 3rd Place Design Award, Inspire Award, Promote Award</li>
                  <li>LA Qualifiers: 1st Place Inspire Award, 2nd Place Think Award, 3rd Place Motivate, Control Award</li>
                  <li>Qualified for 2021 FTC World Championship (Canceled)</li>
                  <br></br>
              <h3><b>2021-2022:  Freight Frenzy</b></h3>
              <hr />
                  <li>SoCal FTC Championship: 1st Place Promote Award, 2nd Place Think Award</li>
                  <li>SoCal Qualifying Tournament: 1st Place Think Award, 2nd Place Inspire Award</li>
                  <br></br>
              <h3><b>2022-2023:  Power Play</b></h3>
              <hr />
                <li>SoCal Qualifying Tournament: 1st Place Control Award, 1st Place Promote Award</li>
                <br></br>
              <h3><b>2023-2024:  Center Stage</b></h3>
              <hr />
                <li>SoCal FTC Championship: 2nd Place Control Award</li>
                <li>SoCal Qualifying Tournament: 1st Place Inspire Award, Finalist Alliance 1st Pick, 3rd Place Control Award, 1st Place Promote Award</li>
            </ul>
          </div>
          <div className='section2' data-aos="fade-up">
            <h1>Meet our Mentors!</h1>
              <br></br>
              <div className='mentors'>
                <p><b>Coach:</b> Terry Domae</p>
                <br></br>
                <p><b>Mentors:</b> Matthew Dung, Steve Wong, Charles Weir, Li-Chen (Gemini) Chang</p>
                <br></br>
                <p><b>Alumni and Mentors:</b> Benjamin Domae, Tony Ngo, Raymond Bolanos, Lucy Wang, Kevin Chou, Sophia Lau, Moses Won, Richard Yeong, Lawrence Dang, Jason Jewik, Tobias Kim, Ian Hudson, Ivan Mao, Amar Ghandi, Nathan Delos Santos, Dina Li, Zunaira Baig, Kirsten Yen, Varn Bharadwaj, Bryant Huang, Saketh Somayajula</p>
            </div>
          </div>
        </div>
        {promptObject}
      </>
    )
  }
}

export default FTC
