import './App.css';
import { useEffect, React } from 'react';
import AOS from "aos";
import "aos/dist/aos.css";


import { Route, Routes} from 'react-router-dom'
import NavBar from './Components/NavBar/index.js'
import Announcement from './Components/Announcement/index.js';
import Footer from './Components/Footer/Footer.js';

import Home from './Pages/Home'
import FTC from './Pages/FTC'
import RoboCamp from './Pages/RoboCamp'
import SIAB from './Pages/SIAB'
import RPEX from './Pages/RPEX'
import Sponsors from './Pages/Sponsors'
import Contact from './Pages/Contact'
import ScrollToTop from './Components/ScrollToTop';
import RoboCampSignup from './Pages/RoboCampSignup';
import IntelliKits from './Pages/IntelliKits';
import FLL from './Pages/FLL';

function App() {

  useEffect(() => {
    AOS.init();
  }, []);
  /*<Announcement {...announcementProps} />*/

  return (
    <>
      <pre>{process.env.REACT_APP_SECRET_NAME}</pre>
      <ScrollToTop />
      <NavBar />
      <div className='container' >
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/FTC" element={<FTC />} />
          <Route path="/RoboCamp" element={<RoboCamp />} />
          <Route path="/Sponsors" element={<Sponsors />} />
          <Route path="/SIAB" element={<SIAB />} />
          <Route path="/Contact" element={<Contact />} />
          <Route path="/RoboCampSignup" element={<RoboCampSignup />} />
          <Route path="/RPEX" element={<RPEX />} />
          <Route path="/IntelliKits" element={<IntelliKits />} />
          <Route path="/FLL" element={<FLL />} />
        </Routes>
      </div>
      <Footer />
    </>
  );
}

export default App;
