import '../../Pages/Styles/RPEX.scss'
import React, { useEffect, useState } from 'react';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import AOS from 'aos';
import 'aos/dist/aos.css';


const Carousel = ({firstDotColor, secondDotColor, items}) => {
  const [responsive, setResponsive] = useState({
    0: { items: 1 },
    568: { items: 2 }
  });

  useEffect(() => {
    const handleWindowResize = () => {
    console.log(window.innerWidth)
      if (window.innerWidth < 1300) {
        setResponsive({ 0: { items: 1 } });
 
      } else {
        setResponsive({ 0: { items: 1 }, 568: { items: 2 } });
      }
    };
    handleWindowResize();
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  return (
    <div className="carousel-container" data-aos="fade-down">
      <AliceCarousel
        mouseTracking
        items={items.map((item, index) => (
          <img className="carousel-item" src={item.src} alt={item.alt} draggable="false" key={index} />
        ))}
        responsive={responsive}
        disableButtonsControls
        dotsDisabled={false} // Show dots
        buttonsDisabled // Hide navigation buttons
        renderDotsItem={({ isActive }) => (
          <span
            className={`dot ${isActive ? 'active-rpex' : ''}`}
            style={{ background: isActive ? firstDotColor : secondDotColor }}
          />
        )}
      />
    </div>
  );
};

export default Carousel;
