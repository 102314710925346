import React from 'react'
import './Styles/IntelliKits.scss'

import ImageSlider from '../Components/ImageSlider/ImageSlider.js'
import {HashLink} from 'react-router-hash-link'
import ContentPrompt from "../Components/ContentPrompt/index.js"
import InfoCard from "../Components/InfoCard/index.js"
import { AiOutlinePlus } from 'react-icons/ai'

import intellikitsCar from "../Assets/IntelliKits/intellikitsCar.png"
import TerranOrbital from "../Assets/IntelliKits/TerranOrbital.jpg"
import sponsorsHoneycomb from "../Assets/IntelliKits/sponsorsHoneycomb.png"

import AOS from 'aos'
import SponsorCard from '../Components/SponsorCard/index.js'

const introImages = [
  {image: intellikitsCar}, {image: intellikitsCar}, {image: intellikitsCar}
]

const annotatedKitRenders =  [
  {image: intellikitsCar}, {image: intellikitsCar}, {image: intellikitsCar}
]

const moreInfoSectionJSX=<>
  <div className='moreInfo'data-aos="fade-up">
    <h1>Program Information</h1>
    <hr />
    <div className='row'>
      <InfoCard name="Release Schedule" desc="DESCRIPTION HERE"/>
      <div className='container'>
        <div className='center'>
          <h1>Any more questions?</h1>
          <a href="#faq"><button className='faqButton'>Click here!</button></a>
        </div>
      </div> 
      <InfoCard name="Other Information" desc="DESCRIPTION HERE"/>
    </div>
  </div>
</>
AOS.init()

class IntelliKits extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {promptEnabled: false, promptName: null, faqEnabled: false}
    this.OpenKitSpecs = this.OpenKitSpecs.bind(this)

  }
  
  OpenKitSpecs() {
    const promptEnabled = this.state.promptEnabled
    if (!promptEnabled) {
      this.setState({promptEnabled: true, promptName: "IntelliKitsInstructions"})
    }
  }

  contentCallback = () => { console.log("callback called"); this.setState({promptEnabled: false}) }

  handleFAQ = (e) => {
    e.target.classList.toggle('active')
    var panel = e.target.nextElementSibling 
    if (panel.style.maxHeight) {
      panel.style.maxHeight = null
    } else {
      panel.style.maxHeight = panel.scrollHeight + "px"
    }
  }
  
  render() {

    let promptObject = (null);
    if (this.state.promptEnabled) {
      promptObject = <ContentPrompt name={this.state.promptName} callback={this.contentCallback}/>
    }

    AOS.init();

    return (
      <>
        <div className='IK-header'>
          <h1>IntelliKits</h1>
          <h2>Our program delivering STEM education to underprivlidged elementary students with interactive kits!</h2>
        </div>
        <div className='IK-content'>
          <div className='announcementcontainer'>
            <div className='announcement'>
              <h2>Our first kit is releasing soon! Keep a look out for it!</h2>
            </div>
          </div>
          <div className='sponsors'>
            <h1>Corporate Sponsors</h1>
            <hr />
            <div className='sponsorcontainer'>
              <SponsorCard logo={TerranOrbital} site="https://terranorbital.com/" title="Terran Orbital" desc="Terran Orbital is a major manufacturer for satellite parts and a major supplier in the aerospace industry. They've manufactured satellites such as Pathfinder-4, which is currently in orbit and being used to test solar panel technology in addition to extremely fast communications between satellites and humans on Earth."/>
            </div>
            <img src={sponsorsHoneycomb} className='honeyComb1'/>
            <img src={sponsorsHoneycomb} className='honeyComb2'/>
          </div>
          <div className='about'>
            <h1 data-aos="fade up">About IntelliKits</h1>
            <hr />
            <h2>IntelliKits is an innovative program which features a custom-designed 3D-printed robotics kit aimed at introducing elementary students to basic physics concepts such as tension, potential energy, and kinetic energy. We have set up a goal to produce and distribute 2,500 kits to students in underserved communities, free of charge. We believe that early exposure to these ideas will ignite curiosity and inspire a lifelong passion for STEM, laying the groundwork for future success. To achieve our goals we are collaborating with other high schools in our school district which includes introducing 3D printer techniques to specially enabled students in one of the high schools.</h2>
            <HashLink smooth to="#exampleKit" data-aos="fade-up">Check out an example kit!</HashLink>
          </div>
          <div className='exampleKit' id="exampleKit" data-aos="fade-up">
            <h1>Example Kit</h1>
            <hr />
            <p>Take a look at an example kit of our car kit, coming October!</p>
            <img src={intellikitsCar} />
          </div>
          <div className='faq' id="faq" data-aos="fade-up">
            <h1>FAQ</h1>
            <hr />
            <h2 className='desc'>Questions? Concerns? We're here to help! Below are a few of the most asked questions. If you need any additional help, feel free to visit our contacts page!</h2>
            <button className='accordion' onClick={this.handleFAQ} >Where can I get access to an IntelliKit?<AiOutlinePlus size="1.25em" /></button>
            <div className='panel'>
              <p>Partner Libraries and Schools - Reach out to intellikits@whitneyrobotics.org</p>
            </div>
            <button className='accordion' onClick={this.handleFAQ} >How much does a kit cost?<AiOutlinePlus size="1.25em" /></button>
            <div className='panel'>
              <p>All kits are distributed free of cost!</p>
            </div>
            <button className='accordion' onClick={this.handleFAQ} >How can I be a sponsor?<AiOutlinePlus size="1.25em" /></button>
            <div className='panel'>
              <p>We would be greatly excited to have your family or company as a sponsor! Please refer to this file for more sponsorship information. Even $50 can make a difference! (Click <a href='https://drive.google.com/file/d/13STWwy6Z2akthdwXBK_4WslYfWYOicJo/view?usp=sharing' target='_blank'>here</a> to see our sponsorship package)
                <br />
                <br />
                Please send us an email at sponsorships@whitneyrobotics.org
              </p>
            </div>
          </div>
        </div>
        {promptObject}
      </>
    )
  }
}

export default IntelliKits