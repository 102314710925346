import React, { useEffect } from 'react'
import './Styles/Contact.scss'

const ContactForm = () => {
  
  const formData = {
    firstName: '',
    lastName: '',
    email: '',
    type: '',
    msg: ''
  }

  function handleChange(event) {
    const target = event.target;
    formData[target.id] = target.value
  }

  useEffect(() => {
    const form = document.getElementById('contact-form')
    form.addEventListener("submit", e => {
      fetch(form.action, {
          method : "POST",
          body: new FormData(document.getElementById("contact-form")),
      }).then(
          response => response.json()
      ).then((html) => {
        alert('Message sent, thank you!')
        form.reset()
      }); 
      e.preventDefault();
    });
    console.log('initialized')
  }, [])

  
  return (
    <>
      <div className='contact-container'>
        <div className='content'>
          <h1 className='title'>Contact our team</h1>
          <h2>Our team is happy to answer any questions or concerns you have! Fill out the form and we'll be in touch as soon as possible.</h2>
          <form id='contact-form' className='form' action={`https://api.sheety.co/896934ec796e34226438a28d9d590d75/faq/sheet1`} method='POST'>
            <input type='hidden' name='_captha' value='false'/>
            <h3>Please be sure to respond to all fields fully</h3>
            <label for='firstName' className='required'>First name</label>
            <input type='text' id='firstName' required onChange={handleChange} name="firstName"/> 
            <hr />
            <label for='lastName' className='required'>Last name</label> 
            <input type='text' id='lastName' required onChange={handleChange} name="lastName"/> 
            <hr />
            <label for='email' className='required'>Email</label> 
            <input type='email' id='email' required onChange={handleChange} name="email"/> 
            <hr />
            <label for='ftype' className='required'>Message Type:</label> 
            <select id="type" onChange={handleChange} name="type">
              <option value="ftc">FTC 542 Team</option>
              <option value="sponsorship">Sponsorship</option>
              <option value="robocamp">RoboCamp</option>
              <option value="outreach">Outreach</option>
              <option value="rpex">RPEX</option>
              <option value="intellikits">IntelliKits</option>
              <option value="other">Other</option>
            </select>
            <hr />
            <label for='fmsg' className='required'>Message:</label>
            <hr style={{height: '1em'}}/>
            <textarea id='msg' required name="msg"></textarea>
            <input type='submit' />
          </form>
        </div>
      </div>
    </>
  )
}

export default ContactForm