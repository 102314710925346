import React, { useEffect } from 'react'

import Lock from '../Assets/Lock.gif'
import './Styles/Lockout.scss'

import {Link} from 'react-router-dom'

const Lockout = () => {

  const Open_Signup = true;
  return (
    <>
    {Open_Signup ? 

    <div>
      {/* Fix later with actual signup form */}
      {window.location.href = 'https://www.eventcreate.com/e/robocamp24'}
    </div> :

    <div className='lockout'>
      <div className='wrapper'>
        <h1><span class='red'>SIGNUPS ARE CLOSED FOR WEEK 1, BUT ARE STILL OPEN FOR WEEK 2</span></h1>
        <hr></hr>
        <h2>As RoboCamp's first week has already started, signups are only open for week two now.</h2>
        <p>Sign up on our hosting partner's website (EventCreate) using the link below!</p>
        <a href="https://www.eventcreate.com/e/robocamp23/">Click here to sign up now!</a>
      </div>
    </div>
    
    }
    </>
  )
}

export default Lockout
